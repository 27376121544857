<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">
        <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
          <div class="text-right">
            <button
              type="button"
              class="btn btn-primary mb-2 mr-2"
              data-toggle="modal"
              data-target="#searchModal"
            >
              <i class="fa fa-search"></i>
            </button>

            <b-button
              v-b-toggle.sidebar-right
              class="btn btn-warning"
              style="background-color: black !important"
              @click.prevent="fetchRenewed()"
            >
              Renewed <i class="fa fa-repeat"></i>
            </b-button>

            <b-sidebar
              id="sidebar-right"
              width="90vw"
              title="Subscription Renewals"
              right
              backdrop
              shadow
            >
              <div class="px-3 py-2">
                <div v-if="loadingRenewed">
                  <b-skeleton-table
                    :rows="10"
                    :columns="5"
                    :table-props="{ bordered: true, striped: true }"
                  ></b-skeleton-table>
                </div>

                <div v-else class="table-responsive mb-4 mt-4">
                  <table
                    id="firstreportsRenewed"
                    class="table table-hover non-hover"
                    style="width: 100%"
                  >
                    <thead>
                      <tr>
                        <th>Patient Name</th>
                        <th>Address</th>
                        <th>Location</th>
                        <th>Date Paid</th>
                        <th>Amount Paid</th>
                        <th>Service Name</th>
                        <th>Doctor Assigned</th>
                        <th>Last Appointment Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="log in renewedRecords" :key="log.id">
                        <td
                          :class="{
                            'text-danger':
                              log.appointments.length == 0 &&
                              isPastThreeBusinessDays(log.created_at),
                          }"
                        >
                          {{ log.firstname }} {{ log.lastname }}
                        </td>
                        <td>{{ log.address }}</td>
                        <td>
                          <span v-if="log.get_state != null">{{
                            log.get_state.name
                          }}</span>
                          <span v-else>{{ log.city }} {{ log.state }} </span>
                        </td>
                        <td>
                          {{
                            formatDate(
                              log.transactions[log.transactions.length - 1]
                                .created_at
                            )
                          }}
                        </td>
                        <td>
                          {{
                            formattedAmount(
                              log.transactions[log.transactions.length - 1]
                                .currency,
                              log.transactions[log.transactions.length - 1]
                                .pay_amount
                            )
                          }}
                        </td>
                        <td>
                          <span
                            v-if="
                              log.transactions.length > 0 &&
                              log.transactions[log.transactions.length - 1]
                                .service != null
                            "
                            >{{
                              log.transactions[log.transactions.length - 1]
                                .service.service_name
                            }}</span
                          >
                        </td>
                        <td>
                          <span v-if="log.doctor == null">Not Matched</span>
                          <span v-else
                            >Dr. {{ log.doctor.firstname }}
                            {{ log.doctor.lastname }}</span
                          >
                        </td>
                        <td>
                          <span v-if="log.appointments.length > 0">{{
                            formatDate(
                              log.appointments[log.appointments.length - 1]
                                .appointment_date
                            )
                          }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </b-sidebar>
          </div>

          <div class="widget-content widget-content-area br-6">
            <div v-if="loading">
              <b-skeleton-table
                :rows="10"
                :columns="5"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>

            <div v-else class="table-responsive mb-4 mt-4">
              <table
                id="firstreports"
                class="table table-hover non-hover"
                style="width: 100%"
              >
                <thead>
                  <tr>
                    <th>Patient Name</th>
                    <th>Address</th>
                    <th>Location</th>
                    <th>Date Paid</th>
                    <th>Amount Paid</th>
                    <th>Service Name</th>
                    <th>Doctor Assigned</th>
                    <th>Status</th>
                    <th>Appointment Date</th>
                    <th>Remarks</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="log in records" :key="log.id">
                    <td
                      :class="{
                        'text-danger':
                          log.appointments.length < 1 &&
                          isPastThreeBusinessDays(log.created_at),
                      }"
                    >
                      {{ log.firstname }} {{ log.lastname }}
                    </td>
                    <td>{{ log.address }}</td>
                    <td>
                      <span v-if="log.get_state != null">
                        {{ log.get_state.name }}
                      </span>
                      <span v-else>{{ log.city }} {{ log.state }} </span>
                    </td>
                    <td>{{ formatDate(log.transactions[0].created_at) }}</td>
                    <td>
                      {{
                        formattedAmount(
                          log.transactions[0].currency,
                          log.transactions[0].pay_amount
                        )
                      }}
                    </td>
                    <!-- <td> {{formatMoney( log.transactions[0].pay_amount)}}</td> -->
                    <td>
                      <span
                        v-if="
                          log.transactions.length > 0 &&
                          log.transactions[0].service != null
                        "
                      >
                        {{ log.transactions[0].service.service_name }}
                      </span>
                    </td>
                    <td>
                      <span v-if="log.doctor == null">Not Matched</span>
                      <span v-else
                        >Dr. {{ log.doctor.firstname }}
                        {{ log.doctor.lastname }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="log.appointments.length > 0"
                        class="btn btn-primary"
                        >Appointment Booked</span
                      >
                      <span v-else class="btn btn-warning">Pending</span>
                    </td>
                    <td>
                      <span v-if="log.appointments.length > 0">
                        {{ formatDate(log.appointments[0].appointment_date) }}
                      </span>
                    </td>
                    <td>{{ log.remark }}</td>
                    <td class="d-flex">
                      <button
                        @click.prevent="showEdit(log)"
                        class="btn btn-sm m-1 btn-outline-warning"
                      >
                        <i class="fa fa-edit"></i>
                      </button>
                      <button
                        v-if="user.role == 'management'"
                        @click.prevent="showHide(log)"
                        class="btn btn-sm m-1 btn-outline-danger"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Modal -->
    <div
      class="modal fade"
      id="editModal"
      role="dialog"
      aria-labelledby="editModalTitle"
      style="display: none"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <i class="flaticon-cancel-12 close" data-dismiss="modal"></i>
            <div class="add-contact-box">
              <div class="add-contact-content">
                <div class="text-center">
                  <p>Edit Remark</p>
                </div>
                <form id="editModalTitle">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="editModalFix"
                          >Medical Services Remark</label
                        >
                        <textarea
                          class="form-control"
                          id="editModalFix"
                          rows="3"
                          v-model="editLog.remark"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-dark" data-dismiss="modal">
              <i class="flaticon-delete-1"></i> Discard
            </button>

            <button
              id="btn-add"
              @click.prevent="saveEditLog()"
              class="btn btn-success"
              :disabled="loadingEdit"
            >
              <b-spinner small v-if="loadingEdit"></b-spinner>
              <span v-else>Update <i class="fa fa-paper-plane"></i></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Search Modal -->
    <div
      class="modal fade"
      id="searchModal"
      role="dialog"
      aria-labelledby="searchModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="searchModalLabel">Search Options</h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="dateSearch">Date Search</label>
                  <select class="form-control" v-model="search" id="dateSearch">
                    <option value="all">All</option>
                    <option value="thisweek">This Week (From Monday)</option>
                    <option value="pastweek">Past Week (Last 7 days)</option>
                    <option value="lastmonth">Last Month</option>
                    <option value="thismonth">This Month</option>
                    <option value="thisyear">This Year</option>
                    <option value="custom">Custom</option>
                  </select>
                </div>

                <div class="form-group" v-if="search == 'custom'">
                  <label>Select a range</label>
                  <div class="input-group">
                    <flat-pickr
                      v-model="date"
                      :config="config"
                      class="form-control"
                      placeholder="Select date"
                      @on-close="handlePicker()"
                      name="date"
                    >
                    </flat-pickr>
                  </div>
                </div>

                <div class="form-group mt-4">
                  <div class="n-chk">
                    <label
                      class="new-control new-radio new-radio-text radio-danger"
                    >
                      <input
                        type="radio"
                        class="new-control-input"
                        value="true"
                        v-model="pending"
                      />
                      <span class="new-control-indicator"></span
                      ><span class="new-radio-content"
                        >Pending Visits Only</span
                      >
                    </label>
                  </div>

                  <div class="n-chk">
                    <label
                      class="new-control new-radio new-radio-text radio-info"
                    >
                      <input
                        type="radio"
                        class="new-control-input"
                        value="false"
                        v-model="pending"
                      />
                      <span class="new-control-indicator"></span
                      ><span class="new-radio-content"
                        >All First Visit Records</span
                      >
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn" data-dismiss="modal">
              <i class="flaticon-cancel-12"></i> Discard
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="handleSearch()"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Hide Modal -->
    <div
      class="modal fade"
      id="hideModal"
      role="dialog"
      aria-labelledby="hideModalTitle"
      style="display: none"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body text-center">
            <i class="flaticon-cancel-12 close" data-dismiss="modal"></i>
            <p>Please confirm you would like to hide this first visit</p>
          </div>
          <div class="modal-footer">
            <button class="btn btn-dark" data-dismiss="modal">
              <i class="flaticon-delete-1"></i> Discard
            </button>

            <button
              id="btn-add"
              @click.prevent="saveHideLog()"
              class="btn btn-danger"
              :disabled="loadingHide"
            >
              <b-spinner small v-if="loadingHide"></b-spinner>
              <span v-else> Hide <i class="fa fa-trash"></i></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "@/utils/http";
import { gerohttp } from "@/utils/gerocarehttp";
import { endpoints, geroendpoints } from "@/utils/endpoints";
import { DatePickerConfig } from "@/models/datepicker.js";
import { mapGetters } from "vuex";
import $ from "jquery";
var moment = require("moment-business-days");

const newYearDay = `${moment().format("YYYY")}-01-01`;
const goodFriday = `${moment().format("YYYY")}-04-02`;
const easterMonday = `${moment().format("YYYY")}-04-05`;
const labourDay = `${moment().format("YYYY")}-05-01`;
const democracyDay = `${moment().format("YYYY")}-06-12`;
const independenceDay = `${moment().format("YYYY")}-10-01`;
const christmasDay = `${moment().format("YYYY")}-12-25`;
const boxingDay = `${moment().format("YYYY")}-12-26`;

moment.updateLocale("us", {
  holidays: [
    newYearDay,
    goodFriday,
    easterMonday,
    labourDay,
    democracyDay,
    independenceDay,
    christmasDay,
    boxingDay,
  ],
  holidayFormat: "YYYY-MM-DD",
  workingWeekdays: [1, 2, 3, 4, 5], // 1 (Monday) to 5 (Friday) as business days.
});

export default {
  name: "FirstVisits",

  data() {
    return {
      loading: true,
      loadingRenewed: true,
      records: [],
      renewedRecords: [],

      search: "lastmonth",
      date: null,
      config: new DatePickerConfig(),
      customStart: "",
      customEnd: "",

      selectedLog: {},

      today: moment().toDate(),

      loadingEdit: false,
      editLog: {
        id: null,
        remark: "",
      },

      hideLog: null,
      loadingHide: false,

      ids: null,

      pending: true,
    };
  },

  mounted() {
    this.fetchFirstVisits();
  },

  computed: {
    ...mapGetters(["user"]),
  },

  methods: {
    initTable() {
      $("#firstreports").DataTable({
        retrieve: true,
        dom: "Bfrtip",
        stateSave: true,
        colReorder: true,
        buttons: [
          {
            extend: "copyHtml5",
            text: 'Copy <i class="fa fa-clone"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "excelHtml5",
            text: 'Excel <i class="fa fa-file-excel"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdfHtml5",
            text: 'PDF <i class="fa fa-file-pdf"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            text: 'Print <i class="fa fa-print"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          "colvis",
        ],
        select: true,
      });
      //dt.columns([1, 6, 7]).visible(false);
    },

    initRenewedTable() {
      $("#firstreportsRenewed").DataTable({
        retrieve: true,
        dom: "Bfrtip",
        stateSave: true,
        colReorder: true,
        buttons: [
          {
            extend: "copyHtml5",
            text: 'Copy <i class="fa fa-clone"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "excelHtml5",
            text: 'Excel <i class="fa fa-file-excel"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdfHtml5",
            text: 'PDF <i class="fa fa-file-pdf"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            text: 'Print <i class="fa fa-print"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          "colvis",
        ],
        select: true,
      });
      //dt.columns([1, 6, 7]).visible(false);
    },

    handleSearch() {
      if (
        this.search == "custom" &&
        (this.customStart == "" || this.customEnd == "")
      ) {
        this.$toast.error("Enter a valid date range");
      } else {
        this.fetchFirstVisits();
        $("#searchModal").modal("hide");
      }
    },

    handlePicker() {
      setTimeout(() => {
        if (this.date.length < 15) {
          this.$toast.error("Enter a valid date range");
        } else {
          var split = this.date.split(" ");
          this.customStart = split[0];
          this.customEnd = split[2];
        }
      }, 500);
    },

    fetchFirstVisits() {
      this.loading = true;
      gerohttp
        .post(geroendpoints.FIRST_VISITS, {
          _key: process.env.VUE_APP_API_SECRET_KEY,
          search: this.search,
          pending: this.pending,
          customStart: this.customStart,
          customEnd: this.customEnd,
        })
        .then((response) => {
          this.records = response.records;
          this.ids = response.ids;
          if (this.ids.length > 0) {
            this.fetchRemarksFromCRM(response.ids);
          } else {
            this.$toast.info("There are no first visit records");
            this.$router.go(-1);
          }
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    fetchRemarksFromCRM(x) {
      http
        .post(endpoints.NEW_FIRST_VISITS, {
          ids: x,
        })
        .then((response) => {
          var data = [];

          for (let index = 0; index < response.length; index++) {
            this.records.filter((x) => {
              if (x.id == response[index].patient_id) {
                x.remark = response[index].remark;
                data.push(x);
              }
            });
          }

          this.records = data;
          this.loading = false;

          setTimeout(() => {
            this.initTable();
          }, 500);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    showEdit(x) {
      this.editLog = x;
      $("#editModal").modal("show");
    },

    saveEditLog() {
      if (this.editLog.remark == "") {
        this.$toast.error("Remark cannot be empty");
      } else {
        this.loadingEdit = true;
        http
          .put(endpoints.UPDATE_FIRST_VISITS, {
            ids: this.ids,
            id: this.editLog.id,
            remark: this.editLog.remark,
          })
          .then(() => {
            this.loadingEdit = false;
            $("#editModal").modal("hide");
            this.fetchFirstVisits();
            this.$toast.success("Remark updated successfully!");
          })
          .catch((error) => {
            this.loadingEdit = false;
            this.$toast.error(
              error.response?.data?.message || error.response?.message
            );
          });
      }
    },

    showHide(x) {
      this.hideLog = x;
      $("#hideModal").modal("show");
    },

    saveHideLog() {
      this.loadingHide = true;
      gerohttp
        .post(geroendpoints.HIDE_FIRST_VISIT, {
          patient_id: this.hideLog.id,
          _key: process.env.VUE_APP_API_SECRET_KEY,
        })
        .then(() => {
          this.loadingHide = false;
          $("#hideModal").modal("hide");
          this.$toast.success("First visit hidden successfully!");
          this.fetchFirstVisits();
        })
        .catch((error) => {
          this.loadingHide = false;
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    isPastThreeBusinessDays(date) {
      var diff = moment().businessDiff(moment(date, "YYYY-MM-DD"));
      return diff > 3 ? true : false;
    },

    fetchRenewed() {
      this.loadingRenewed = true;
      gerohttp
        .get(geroendpoints.RENEWED_FIRST_VISITS)
        .then((response) => {
          console.log(response);
          this.loadingRenewed = false;
          this.renewedRecords = response;
          setTimeout(() => {
            this.initRenewedTable();
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
  },
};
</script>

<style scoped src="@/assets/css/tables/table-basic.css"></style>
